<template>
    <div class="p-5">
        <div class="bg-white border rounded-sm ">
            <form-customer ref="formCustomer" v-model="customer" @setDisabled="onDisabled($event)" :type="'edit'" :editCustomer="dataCustomer"></form-customer>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import formCustomer from '../../components/formCustomer.vue'
import {EventBus} from '../../event-bus'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
export default {
    components: {formCustomer},
    mixins: [BreadcrumbManager],
    data () {
        return {
            dataCustomer: '',
            customer: '',
        }
    },
    mounted () {
        this.getData(this.$route.params.id)
        EventBus.$on('onCancel', this.onCancel)
        EventBus.$on('onClick', this.onSubmit)
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setDiscardText('DISCARD')
    },
    destroyed () {
        EventBus.$off('onClick', this.onSubmit)
        EventBus.$off('onCancel', this.onCancel)
    },
    methods: {
        getData (id) {
            axios.get('/customers/edit/'+id)
            .then(res => {
                this.dataCustomer = res.data
            })
        },
        onDisabled(e) {
            this.setDisabled(e)
        },
        onSubmit () {
            this.$refs.formCustomer.onSubmit()
        },
        onCancel () {
            this.popBreadcrumb()
            history.back()
        }
    },
    watch: {
        customer (val) {
            if(val) {
                this.popBreadcrumb()
                this.$router.push('/customers/detail/'+val._id
                )
            }
        }
    }
}
</script>